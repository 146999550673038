import React from 'react';

import Icon from './Icon';

export default function BrowserExtensionIcon({
  className = '',
  text = 'BrowserExtension',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.46979 11.4647C7.76268 11.1718 8.23755 11.1718 8.53045 11.4647L8.53545 11.4697C8.82834 11.7626 8.82834 12.2374 8.53545 12.5303L8.53045 12.5353C8.23755 12.8282 7.76268 12.8282 7.46979 12.5353L7.46479 12.5303C7.17189 12.2374 7.17189 11.7626 7.46479 11.4697L7.46979 11.4647Z"
          fill="currentColor"
        />
        <path
          d="M9.96979 11.4647C10.2627 11.1718 10.7376 11.1718 11.0304 11.4647L11.0354 11.4697C11.3283 11.7626 11.3283 12.2374 11.0354 12.5303L11.0304 12.5353C10.7376 12.8282 10.2627 12.8282 9.96979 12.5353L9.96479 12.5303C9.67189 12.2374 9.67189 11.7626 9.96479 11.4697L9.96979 11.4647Z"
          fill="currentColor"
        />
        <path
          d="M13.5304 11.4647C13.2376 11.1718 12.7627 11.1718 12.4698 11.4647L12.4648 11.4697C12.1719 11.7626 12.1719 12.2374 12.4648 12.5303L12.4698 12.5353C12.7627 12.8282 13.2376 12.8282 13.5304 12.5353L13.5354 12.5303C13.8283 12.2374 13.8283 11.7626 13.5354 11.4697L13.5304 11.4647Z"
          fill="currentColor"
        />
        <path
          d="M14.1684 2.49988C9.91814 1.52004 5.53286 3.47373 3.41885 7.28893C1.30483 11.1041 1.97335 15.8581 5.05756 18.9424C8.14178 22.0266 12.8958 22.6951 16.711 20.5811C20.5262 18.4671 22.4799 14.0818 21.5 9.83152C21.407 9.42789 21.0044 9.17612 20.6007 9.26917C20.1971 9.36222 19.9453 9.76486 20.0384 10.1685C20.7996 13.4702 19.4697 16.8682 16.7499 18.7885V11.5C16.7499 9.70507 15.2948 8.25 13.4999 8.25H4.60601C4.64628 8.17154 4.68791 8.09351 4.73089 8.01593C6.52061 4.786 10.2332 3.13201 13.8314 3.96154C14.2351 4.05459 14.6377 3.80282 14.7307 3.3992C14.8238 2.99557 14.572 2.59293 14.1684 2.49988ZM4.01256 9.75H13.4999C14.4664 9.75 15.2499 10.5335 15.2499 11.5V14.25H3.99716C3.59118 12.7911 3.58802 11.2322 4.01256 9.75ZM4.57671 15.75H15.2499V19.6299C12.1623 20.9631 8.53482 20.2983 6.11823 17.8817C5.48113 17.2446 4.96578 16.5233 4.57671 15.75Z"
          fill="currentColor"
        />
        <path
          d="M19 2.25C17.4812 2.25 16.25 3.48122 16.25 5C16.25 6.51878 17.4812 7.75 19 7.75C20.5188 7.75 21.75 6.51878 21.75 5C21.75 3.48122 20.5188 2.25 19 2.25ZM17.75 5C17.75 4.30964 18.3096 3.75 19 3.75C19.6904 3.75 20.25 4.30964 20.25 5C20.25 5.69036 19.6904 6.25 19 6.25C18.3096 6.25 17.75 5.69036 17.75 5Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
